import { getAuthToken } from '#/models/api/token';

export type RequestMethod = 'GET' | 'POST' | 'PUT' | 'DELETE';

export function isError(obj: unknown): obj is Error {
  if (obj && typeof obj === 'object') {
    return typeof (obj as Error).message === 'string' && typeof (obj as Error).name === 'string';
  }

  return false;
}

export async function request<T>(url: string, method: RequestMethod, data: T): Promise<void> {
  // https://developer.mozilla.org/ja/docs/Web/API/Fetch_API/Using_Fetch
  let body: BodyInit | undefined;
  let u: string = url;

  if (method === 'GET') {
    if (data && Object.keys(data).length > 0) {
      // @ts-ignore
      const init = Object.keys(data).map((key) => [key, `${data[key]}`]);
      const param = new URLSearchParams(init);

      u = `${url}?${param.toString()}`;
    } else {
      u = url;
    }
  } else {
    u = url;
    body = JSON.stringify(data);
  }

  const response = await fetch(u, {
    method,
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      Authorization: `Bearer ${getAuthToken()}`,
    },
    body,
  });

  if (!response.ok) {
    console.error(response);

    const e: Error = {
      name: 'error',
      message: 'サーバーとの通信中にエラーが発生しました',
    };

    throw e;
  }

  const json: { error?: Error; message?: Error } = await response.json();

  console.log(json);

  if (isError(json.error)) {
    throw json.error;
  }
}
