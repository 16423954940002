import * as React from 'react';

import { MailSetting } from '#/models/mails';
import { MsvcMailSettingEditor } from '#/react/components/mails/msvc/MsvcMailSettingEditor';

export interface MailSettingEditorProps {
  value: MailSetting;
  onChange(value: MailSetting): void;
}

export const MailSettingEditor: React.FunctionComponent<MailSettingEditorProps> = (props) => {
  const { value, onChange } = props;

  return (
    <>
      {/*
      <h2>コントローラー選択</h2>

      <div className="mb-3 row">
        <div className="col-sm-3">
          <div className="d-grid gap-2">
            <button type="button" className={value.kind === 'msvc' ? 'btn btn-primary' : 'btn btn-secondary'}>
              CATV 監視装置コントローラー
            </button>
          </div>
        </div>
        <div className="col-sm-3">
          <div className="d-grid gap-2">
            <button type="button" className="btn btn-secondary">
              FTTH 監視装置コントローラー
            </button>
          </div>
        </div>
        <div className="col-sm-3">
          <div className="d-grid gap-2">
            <button type="button" className="btn btn-secondary">
              GE-PON コントローラー
            </button>
          </div>
        </div>
      </div>
      */}

      {(() => {
        if (value.kind === 'msvc') {
          return <MsvcMailSettingEditor value={value.msvc} onChange={(msvc) => onChange({ ...value, msvc })} />;
        }

        return <></>;
      })()}
    </>
  );
};
