import { format } from 'date-fns';

import { Mail } from '#/models/mails/mail';

export interface MsvcRfError {
  readonly errorNimUnlock: boolean;

  readonly recoverNimUnlock: boolean;

  readonly errorLevel: boolean;

  readonly recoverLevel: boolean;

  readonly errorBer: boolean;

  readonly recoverBer: boolean;
}

export interface MsvcTsError {
  readonly errorPatCcError: boolean;

  readonly errorPatTimeout: boolean;

  readonly recoverPat: boolean;

  readonly errorTsSync: boolean;

  readonly recoverTsSync: boolean;
}

export interface MsvcVideoFreezeError {
  readonly id: number;
  readonly title: string;
  readonly thumbnail: string;
}

export namespace MsvcVideoFreezeError {
  export const all: ReadonlyArray<MsvcVideoFreezeError> = [
    {
      id: 1,
      title: 'カラーバー',
      thumbnail: './image/freeze/01_colorbar.jpg',
    },
    {
      id: 2,
      title: '囲碁',
      thumbnail: './image/freeze/02_igo.jpg',
    },
    {
      id: 3,
      title: 'オッズ',
      thumbnail: './image/freeze/03_odds.jpg',
    },
    {
      id: 4,
      title: '将棋',
      thumbnail: './image/freeze/04_shogi.jpg',
    },
    {
      id: 5,
      title: 'バイクレース',
      thumbnail: './image/freeze/06_others_01.jpg',
    },
    {
      id: 6,
      title: 'バドミントン',
      thumbnail: './image/freeze/06_others_02.jpg',
    },
    {
      id: 7,
      title: '野球中継',
      thumbnail: './image/freeze/06_others_03.jpg',
    },
    {
      id: 8,
      title: '麻雀',
      thumbnail: './image/freeze/06_others_04.jpg',
    },
  ];
}

export async function fetchAndEncodeImage(url: string): Promise<string> {
  // URLから画像を取得
  const response = await fetch(url);

  // レスポンスをArrayBufferに変換
  const buffer = await response.arrayBuffer();

  // ArrayBufferをBase64エンコード
  const base64Image = btoa(new Uint8Array(buffer).reduce((data, byte) => data + String.fromCharCode(byte), ''));

  // Base64エンコードされた画像データを返す
  return base64Image;
}

export interface MsvcAvError {
  readonly kind: 'error-freeze' | 'recover-video' | 'error-no-sound' | 'recover-audio';
  readonly videoFreeze: MsvcVideoFreezeError;
}

export interface MsvcMailSetting {
  readonly kind: 'rf' | 'ts' | 'av';
  readonly rf: MsvcRfError;
  readonly ts: MsvcTsError;
  readonly av: MsvcAvError;
}

export namespace MsvcMailSetting {
  export function empty(): MsvcMailSetting {
    return {
      kind: 'rf',
      rf: {
        errorNimUnlock: true,
        recoverNimUnlock: false,
        errorLevel: false,
        recoverLevel: false,
        errorBer: false,
        recoverBer: false,
      },
      ts: {
        errorPatCcError: false,
        errorPatTimeout: true,
        recoverPat: false,
        errorTsSync: false,
        recoverTsSync: false,
      },
      av: {
        kind: 'error-freeze',
        videoFreeze: MsvcVideoFreezeError.all[0],
      },
    };
  }

  export function toDateTimeText(date: Date): string {
    return format(date, 'yyyy/MM/dd HH:mm:ss');
  }

  export async function toMail(setting: MsvcMailSetting): Promise<Mail> {
    if (setting.kind === 'rf') {
      let body = '';

      if (setting.rf.errorNimUnlock) {
        body += `HE:BSパススルー:BS-15
  ＮＨＫＢＳ
  スターチャンネル２・スターチャンネル３
[異常]"受信状態"
${toDateTimeText(new Date())}

`;
      }

      if (setting.rf.recoverNimUnlock) {
        body += `HE:BSパススルー:BS-15
  ＮＨＫＢＳ
  スターチャンネル２・スターチャンネル３
[復帰]"受信状態"
${toDateTimeText(new Date())}

`;
      }

      if (setting.rf.errorLevel) {
        body += `HE:BSパススルー:BS-15
  ＮＨＫＢＳ
  スターチャンネル２・スターチャンネル３
[異常]"信号レベル(68dBμV)"
${toDateTimeText(new Date())}

`;
      }

      if (setting.rf.recoverLevel) {
        body += `HE:BSパススルー:BS-15
  ＮＨＫＢＳ
  スターチャンネル２・スターチャンネル３
[復帰]"信号レベル(72dBμV)"
${toDateTimeText(new Date())}

`;
      }

      if (setting.rf.errorBer) {
        body += `HE:BSパススルー:BS-15
  ＮＨＫＢＳ
  スターチャンネル２・スターチャンネル３
[異常]"BER(1.47E-2)"
${toDateTimeText(new Date())}

`;
      }

      if (setting.rf.recoverBer) {
        body += `HE:BSパススルー:BS-15
  ＮＨＫＢＳ
  スターチャンネル２・スターチャンネル３
[復帰]"BER(1.00E-7 未満)"
${toDateTimeText(new Date())}

`;
      }

      return {
        title: '[RF]CATV監視',
        body: body.trim(),
        attachmentFileName: undefined,
        attachmentFileBody: undefined,
      };
    }

    if (setting.kind === 'ts') {
      let body = '';

      if (setting.ts.errorPatCcError) {
        body += `HE:BSパススルー:BS-15
  スターチャンネル２・スターチャンネル３
[警告]"PAT 連続性異常"
${toDateTimeText(new Date())}

`;
      }

      if (setting.ts.errorPatTimeout) {
        body += `HE:BSパススルー:BS-15
  スターチャンネル２・スターチャンネル３
[異常]"PAT タイムアウト"
${toDateTimeText(new Date())}

`;
      }

      if (setting.ts.recoverPat) {
        body += `HE:BSパススルー:BS-15
  スターチャンネル２・スターチャンネル３
[復帰]"PAT 正常"
${toDateTimeText(new Date())}

`;
      }

      if (setting.ts.errorTsSync) {
        body += `HE:BSパススルー:BS-1
  ＢＳテレ東
[異常]"TS同期"
${toDateTimeText(new Date())}

`;
      }

      if (setting.ts.recoverTsSync) {
        body += `HE:BSパススルー:BS-1
  ＢＳテレ東
[復帰]"TS同期"
${toDateTimeText(new Date())}

`;
      }

      return {
        title: '[TS/TLV]CATV監視',
        body: body.trim(),
        attachmentFileName: undefined,
        attachmentFileBody: undefined,
      };
    }

    if (setting.kind === 'av') {
      if (setting.av.kind === 'error-freeze') {
        const fileName = setting.av.videoFreeze.thumbnail.split('/').pop()!;
        const fileBody = await fetchAndEncodeImage(setting.av.videoFreeze.thumbnail);

        return {
          title: '[映像]CATV監視',
          body: `HE:BSパススルー:BS-1
  ＢＳテレ東
[異常]"映像(フリーズ)"
${toDateTimeText(new Date())}
`,
          attachmentFileName: fileName,
          attachmentFileBody: fileBody,
        };
      }

      if (setting.av.kind === 'recover-video') {
        return {
          title: '[映像]CATV監視',
          body: `HE:BSパススルー:BS-1
  ＢＳテレ東
[復帰]"映像(正常)"
${toDateTimeText(new Date())}
`,
          attachmentFileName: undefined,
          attachmentFileBody: undefined,
        };
      }

      if (setting.av.kind === 'error-no-sound') {
        return {
          title: '[音声]CATV監視',
          body: `HE:BSパススルー:BS-1
  ＢＳテレ東
[異常]"音声(無音)"
${toDateTimeText(new Date())}
`,
          attachmentFileName: undefined,
          attachmentFileBody: undefined,
        };
      }

      if (setting.av.kind === 'recover-audio') {
        return {
          title: '[音声]CATV監視',
          body: `HE:BSパススルー:BS-1
  ＢＳテレ東
[復帰]"音声(正常)"
${toDateTimeText(new Date())}
`,
          attachmentFileName: undefined,
          attachmentFileBody: undefined,
        };
      }
    }

    throw new Error('Invalid mail setting kind');
  }
}
