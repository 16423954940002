import * as React from 'react';

import { MsvcMailSetting } from '#/models/mails/msvc';
import { MsvcRfErrorEditor } from '#/react/components/mails/msvc/MsvcRfErrorEditor';
import { MsvcTsErrorEditor } from '#/react/components/mails/msvc/MsvcTsErrorEditor';
import { MsvcAvErrorEditor } from '#/react/components/mails/msvc/MsvcAvErrorEditor';

export interface MsvcMailSettingEditorProps {
  value: MsvcMailSetting;
  onChange(value: MsvcMailSetting): void;
}

export const MsvcMailSettingEditor: React.FunctionComponent<MsvcMailSettingEditorProps> = (props) => {
  const { value, onChange } = props;

  return (
    <>
      <h2>ログ選択</h2>
      <div className="mb-3 row">
        <div className="col-sm-3">
          <div className="d-grid gap-2">
            <button
              type="button"
              className={value.kind === 'rf' ? 'btn btn-primary' : 'btn btn-secondary'}
              onClick={(e) => {
                e.preventDefault();
                onChange({ ...value, kind: 'rf' });
              }}
            >
              RF 異常
            </button>
          </div>
        </div>
        <div className="col-sm-3">
          <div className="d-grid gap-2">
            <button
              type="button"
              className={value.kind === 'ts' ? 'btn btn-primary' : 'btn btn-secondary'}
              onClick={(e) => {
                e.preventDefault();
                onChange({ ...value, kind: 'ts' });
              }}
            >
              TS 異常
            </button>
          </div>
        </div>
        <div className="col-sm-3">
          <div className="d-grid gap-2">
            <button
              type="button"
              className={value.kind === 'av' ? 'btn btn-primary' : 'btn btn-secondary'}
              onClick={(e) => {
                e.preventDefault();
                onChange({ ...value, kind: 'av' });
              }}
            >
              画音異常
            </button>
          </div>
        </div>
      </div>

      {(() => {
        if (value.kind === 'rf') {
          return <MsvcRfErrorEditor value={value.rf} onChange={(rf) => onChange({ ...value, rf })} />;
        }

        if (value.kind === 'ts') {
          return <MsvcTsErrorEditor value={value.ts} onChange={(ts) => onChange({ ...value, ts })} />;
        }

        if (value.kind === 'av') {
          return <MsvcAvErrorEditor value={value.av} onChange={(av) => onChange({ ...value, av })} />;
        }
      })()}
    </>
  );
};
