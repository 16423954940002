import * as React from 'react';
import { useControlId } from '@phaier/react-helper';
import { MsvcRfError } from '#/models/mails/msvc';

export interface MsvcRfErrorEditorProps {
  value: MsvcRfError;
  onChange(value: MsvcRfError): void;
}

export const MsvcRfErrorEditor: React.FunctionComponent<MsvcRfErrorEditorProps> = (props) => {
  const { value, onChange } = props;

  const inputErrorNimUnlock = useControlId();
  const inputRecoverNimUnlock = useControlId();
  const inputErrorLevel = useControlId();
  const inputRecoverLevel = useControlId();
  const inputErrorBer = useControlId();
  const inputRecoverBer = useControlId();

  return (
    <>
      <h2>RF 異常</h2>
      <div className="mb-3 row">
        <div className="col-sm-12">
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              id={inputErrorNimUnlock}
              checked={value.errorNimUnlock}
              onChange={(e) => {
                onChange({ ...value, errorNimUnlock: e.target.checked });
              }}
            />
            <label className="form-check-label" htmlFor={inputErrorNimUnlock}>
              受信異常発生
            </label>
          </div>
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              id={inputRecoverNimUnlock}
              checked={value.recoverNimUnlock}
              onChange={(e) => onChange({ ...value, recoverNimUnlock: e.target.checked })}
            />
            <label className="form-check-label" htmlFor={inputRecoverNimUnlock}>
              受信異常復帰
            </label>
          </div>

          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              value=""
              id={inputErrorLevel}
              checked={value.errorLevel}
              onChange={(e) => onChange({ ...value, errorLevel: e.target.checked })}
            />
            <label className="form-check-label" htmlFor={inputErrorLevel}>
              レベル異常発生
            </label>
          </div>
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              value=""
              id={inputRecoverLevel}
              checked={value.recoverLevel}
              onChange={(e) => onChange({ ...value, recoverLevel: e.target.checked })}
            />
            <label className="form-check-label" htmlFor={inputRecoverLevel}>
              レベル異常復帰
            </label>
          </div>

          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              value=""
              id={inputErrorBer}
              checked={value.errorBer}
              onChange={(e) => onChange({ ...value, errorBer: e.target.checked })}
            />
            <label className="form-check-label" htmlFor={inputErrorBer}>
              BER 異常発生
            </label>
          </div>
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              value=""
              id={inputRecoverBer}
              checked={value.recoverBer}
              onChange={(e) => onChange({ ...value, recoverBer: e.target.checked })}
            />
            <label className="form-check-label" htmlFor={inputRecoverBer}>
              BER 異常復帰
            </label>
          </div>
        </div>
      </div>
    </>
  );
};
