import * as React from 'react';
import { useControlId } from '@phaier/react-helper';

import { MsvcTsError } from '#/models/mails/msvc';

export interface MsvcTsErrorEditorProps {
  value: MsvcTsError;
  onChange(value: MsvcTsError): void;
}

export const MsvcTsErrorEditor: React.FunctionComponent<MsvcTsErrorEditorProps> = (props) => {
  const { value, onChange } = props;

  const inputErrorPatCcError = useControlId();
  const inputErrorPatTimeout = useControlId();
  const inputRecoverPat = useControlId();
  const inputErrorTsSync = useControlId();
  const inputRecoverTsSync = useControlId();

  return (
    <>
      <h2>TS 異常</h2>
      <div className="mb-3 row">
        <div className="col-sm-12">
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              id={inputErrorPatCcError}
              checked={value.errorPatCcError}
              onChange={(e) => {
                onChange({ ...value, errorPatCcError: e.target.checked });
              }}
            />
            <label className="form-check-label" htmlFor={inputErrorPatCcError}>
              PAT 連続性異常発生
            </label>
          </div>
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              id={inputErrorPatTimeout}
              checked={value.errorPatTimeout}
              onChange={(e) => onChange({ ...value, errorPatTimeout: e.target.checked })}
            />
            <label className="form-check-label" htmlFor={inputErrorPatTimeout}>
              PAT タイムアウト発生
            </label>
          </div>
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              value=""
              id={inputRecoverPat}
              checked={value.recoverPat}
              onChange={(e) => onChange({ ...value, recoverPat: e.target.checked })}
            />
            <label className="form-check-label" htmlFor={inputRecoverPat}>
              PAT 正常
            </label>
          </div>

          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              value=""
              id={inputErrorTsSync}
              checked={value.errorTsSync}
              onChange={(e) => onChange({ ...value, errorTsSync: e.target.checked })}
            />
            <label className="form-check-label" htmlFor={inputErrorTsSync}>
              TS 同期異常発生
            </label>
          </div>
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              value=""
              id={inputRecoverTsSync}
              checked={value.recoverTsSync}
              onChange={(e) => onChange({ ...value, recoverTsSync: e.target.checked })}
            />
            <label className="form-check-label" htmlFor={inputRecoverTsSync}>
              TS 同期異常復帰
            </label>
          </div>
        </div>
      </div>
    </>
  );
};
