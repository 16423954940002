import { MailSetting } from '#/models/mails';
import { Mail } from '#/models/mails/mail';
import { MailSettingEditor } from '#/react/components/mails/MailSettingEditor';
import * as React from 'react';

export interface AppProps {}

export interface AppState {
  setting: MailSetting;
  mail: undefined | Mail;
}

export class App extends React.Component<AppProps, AppState> {
  constructor(props: AppProps) {
    super(props);

    this.state = {
      setting: MailSetting.empty(),
      mail: undefined,
    };
  }

  handleOnChangeSetting = (value: MailSetting) => {
    this.setState({
      setting: value,
    });
  };

  handleSendMail = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();

    const { setting } = this.state;
    const mail = await MailSetting.toMail(setting);
    console.log(mail);

    this.setState({
      mail,
    });

    try {
      await MailSetting.sendMail(mail);

      window.alert('メールを送信しました。');
    } catch (ex) {
      console.error(ex);
      window.alert(`メールの送信に失敗しました: ${(ex as Error).message}`);
    }
  };

  handleCancel = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();

    this.setState({
      setting: MailSetting.empty(),
      mail: undefined,
    });
  };

  render(): React.ReactNode {
    const { setting, mail } = this.state;

    return (
      <>
        <section className="py-5 text-center container">
          <div className="row py-lg-5">
            <div className="col-lg-6 col-md-8 mx-auto">
              <h1 className="fw-light">M-3 メール転送 デモ</h1>
              <p className="lead text-body-secondary">
                CATV 監視装置コントローラーから発生するメールを疑似的に発報します。
              </p>
            </div>
          </div>
        </section>

        <section className="container">
          <div className="row">
            <div className="col-md-12">
              <MailSettingEditor value={setting} onChange={this.handleOnChangeSetting} />

              <div className="mb-3 row">
                <div className="col-sm-3">
                  <div className="d-grid gap-2">
                    <button type="button" className="btn btn-primary" onClick={this.handleSendMail}>
                      メールを送信する
                    </button>
                  </div>
                </div>
                <div className="col-sm-3">
                  <div className="d-grid gap-2">
                    <button type="button" className="btn btn-secondary" onClick={this.handleCancel}>
                      キャンセル
                    </button>
                  </div>
                </div>
              </div>

              {(() => {
                if (mail) {
                  const lines = mail.body.split(/\r?\n/).length;

                  return (
                    <>
                      <h2>送信したメール</h2>
                      <div className="mb-3 row">
                        <label htmlFor="staticEmail" className="col-sm-3 col-form-label">
                          件名
                        </label>
                        <div className="col-sm-9">
                          <input
                            type="text"
                            readOnly
                            className="form-control-plaintext"
                            id="staticEmail"
                            value={mail.title}
                          />
                        </div>
                      </div>
                      <div className="mb-3 row">
                        <label htmlFor="staticEmail" className="col-sm-3 col-form-label">
                          本文
                        </label>
                        <div className="col-sm-9">
                          <textarea
                            readOnly
                            className="form-control-plaintext"
                            id="staticEmail"
                            value={mail.body}
                            rows={lines + 1}
                          />
                        </div>
                      </div>

                      {(() => {
                        if (mail.attachmentFileName) {
                          return (
                            <>
                              <div className="mb-3 row">
                                <label htmlFor="staticEmail" className="col-sm-3 col-form-label">
                                  添付ファイル名
                                </label>
                                <div className="col-sm-9">
                                  <input
                                    type="text"
                                    readOnly
                                    className="form-control-plaintext"
                                    id="staticEmail"
                                    value={mail.attachmentFileName}
                                  />
                                </div>
                              </div>

                              <div className="mb-3 row">
                                <label htmlFor="staticEmail" className="col-sm-3 col-form-label">
                                  添付ファイル名
                                </label>
                                <div className="col-sm-9">
                                  <img
                                    src={`data:image/png;base64,${mail.attachmentFileBody}`}
                                    className="img-fluid"
                                    alt="..."
                                  />
                                </div>
                              </div>
                            </>
                          );
                        }

                        return <></>;
                      })()}
                    </>
                  );
                }

                return <> </>;
              })()}
            </div>
          </div>
        </section>
      </>
    );
  }
}
