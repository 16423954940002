import * as React from 'react';
import { useControlId } from '@phaier/react-helper';
import { MsvcAvError, MsvcVideoFreezeError } from '#/models/mails/msvc';

export interface MsvcAvErrorEditorProps {
  value: MsvcAvError;
  onChange(value: MsvcAvError): void;
}

export const MsvcAvErrorEditor: React.FunctionComponent<MsvcAvErrorEditorProps> = (props) => {
  const { value, onChange } = props;
  const id = useControlId();

  return (
    <>
      <h2>画音異常</h2>
      <div className="mb-3 row">
        <div className="col-sm-3">
          <div className="d-grid gap-2">
            <button
              type="button"
              className={value.kind === 'error-freeze' ? 'btn btn-primary' : 'btn btn-secondary'}
              onClick={(e) => {
                e.preventDefault();
                onChange({ ...value, kind: 'error-freeze' });
              }}
            >
              フリーズ発生
            </button>
          </div>
        </div>
        <div className="col-sm-3">
          <div className="d-grid gap-2">
            <button
              type="button"
              className={value.kind === 'recover-video' ? 'btn btn-primary' : 'btn btn-secondary'}
              onClick={(e) => {
                e.preventDefault();
                onChange({ ...value, kind: 'recover-video' });
              }}
            >
              映像異常復帰
            </button>
          </div>
        </div>
        <div className="col-sm-3">
          <div className="d-grid gap-2">
            <button
              type="button"
              className={value.kind === 'error-no-sound' ? 'btn btn-primary' : 'btn btn-secondary'}
              onClick={(e) => {
                e.preventDefault();
                onChange({ ...value, kind: 'error-no-sound' });
              }}
            >
              無音発生
            </button>
          </div>
        </div>
        <div className="col-sm-3">
          <div className="d-grid gap-2">
            <button
              type="button"
              className={value.kind === 'recover-audio' ? 'btn btn-primary' : 'btn btn-secondary'}
              onClick={(e) => {
                e.preventDefault();
                onChange({ ...value, kind: 'recover-audio' });
              }}
            >
              音声異常復帰
            </button>
          </div>
        </div>
      </div>

      {(() => {
        if (value.kind === 'error-freeze') {
          return (
            <>
              <h2>画像選択</h2>
              <div className="mb-3 row row-cols-1 row-cols-sm-2 row-cols-md-4 g-3">
                {MsvcVideoFreezeError.all.map((error) => {
                  const key = error.id;

                  return (
                    <div className="col-sm-3" key={key}>
                      <div className="card">
                        <img src={error.thumbnail} className="card-img-top" alt={error.title} />
                        <div className="card-body">
                          <h5 className="card-title">{error.title}</h5>
                          <a
                            href="#"
                            className={value.videoFreeze.id === error.id ? 'btn btn-primary' : 'btn btn-secondary'}
                            onClick={(e) => {
                              e.preventDefault();
                              onChange({ ...value, videoFreeze: error });
                            }}
                          >
                            選択する
                          </a>
                        </div>
                      </div>
                    </div>
                  );
                })}

                {/*
                <div className="col-sm-3">
                  <div className="card">
                    <img src="./image/freeze/01_colorbar.jpg" className="card-img-top" alt="..." />
                    <div className="card-body">
                      <h5 className="card-title">囲碁</h5>
                      <a href="#" className="btn btn-primary">
                        選択する
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-sm-3">
                  <div className="card">
                    <img src="./image/freeze/02_igo.jpg" className="card-img-top" alt="..." />
                    <div className="card-body">
                      <h5 className="card-title">囲碁</h5>
                      <a href="#" className="btn btn-primary">
                        選択する
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-sm-3">
                  <div className="card">
                    <img src="./image/freeze/03_odds.jpg" className="card-img-top" alt="..." />
                    <div className="card-body">
                      <h5 className="card-title">囲碁</h5>
                      <a href="#" className="btn btn-primary">
                        選択する
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-sm-3">
                  <div className="card">
                    <img src="./image/freeze/04_shogi.jpg" className="card-img-top" alt="..." />
                    <div className="card-body">
                      <h5 className="card-title">囲碁</h5>
                      <a href="#" className="btn btn-primary">
                        選択する
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-sm-3">
                  <div className="card">
                    <img src="./image/freeze/06_others_01.jpg" className="card-img-top" alt="..." />
                    <div className="card-body">
                      <h5 className="card-title">囲碁</h5>
                      <a href="#" className="btn btn-primary">
                        選択する
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-sm-3">
                  <div className="card">
                    <img src="./image/freeze/vod-car-view.jpg" className="card-img-top" alt="..." />
                    <div className="card-body">
                      <h5 className="card-title">囲碁</h5>
                      <a href="#" className="btn btn-primary">
                        選択する
                      </a>
                    </div>
                  </div>
                </div>
                */}
              </div>
            </>
          );
        }

        return <></>;
      })()}
    </>
  );
};
