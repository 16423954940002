import { request } from '#/models/api';
import { Mail } from '#/models/mails/mail';
import { MsvcMailSetting } from '#/models/mails/msvc';

export interface MailSetting {
  readonly kind: 'msvc';
  readonly msvc: MsvcMailSetting;
}

export namespace MailSetting {
  export function empty(): MailSetting {
    return {
      kind: 'msvc',
      msvc: MsvcMailSetting.empty(),
    };
  }

  export async function toMail(setting: MailSetting): Promise<Mail> {
    if (setting.kind === 'msvc') {
      return await MsvcMailSetting.toMail(setting.msvc);
    }

    throw new Error('Invalid mail setting kind');
  }

  export async function sendMail(mail: Mail): Promise<void> {
    await request('https://api.m3-demo-cts-2023.miharu-demo.com/send-mail', 'POST', mail);
  }
}
