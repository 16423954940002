import * as React from 'react';

import { createRoot } from 'react-dom/client';

import { App } from '#/react/containers/index/App';

import './index.scss';
import { setAuthToken } from '#/models/api/token';

const hash = location.hash;
if (hash) {
  setAuthToken(hash.slice(1));
}

const elem = document.getElementById('application')!;
const root = createRoot(elem);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
